import Service from "../Service";

const resource = "definitiongroup/";
const definitiongroupdetail = "definitiongroupdetail/";

export default {
  save(dgr, requestID) {
    return Service.post(resource + "save", dgr, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  insertDetail(dgd, requestID) {
    return Service.post(definitiongroupdetail + "insert", dgd, {
      params: { requestID: requestID },
    });
  },
  updateDetail(dgd, requestID) {
    return Service.post(definitiongroupdetail + "update", dgd, {
      params: { requestID: requestID },
    });
  },
};
