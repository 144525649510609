<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <s-crud
          formPermanent
          title="Grupo de Definición"
          pdf
          excel
          edit
          add
          remove
          :config="dgrConfig"
          @save="saveDgr($event)"
          @rowSelected="rowSelected($event)"
          sortable
        >
          <template slot-scope="props">
            <v-container v-if="props.item != null" class="pb-0">
              <v-row>
                <v-col cols="12" class="s-col-form">
                  <s-text v-model="props.item.DgrDescription" label="Grupo" autofocus></s-text>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="s-card border-title" :disabled="disabledDetail">
          <v-card-title>
            <h3>Detalle</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-container>
                <v-col>
                  <v-autocomplete
                    v-model="defID"
                    item-value="DefID"
                    item-text="DefDescription"
                    :items="definitionList"
                    label="Definición"
                    dense
                    outlined
                    hide-details
                    clearable
                    :disabled="disabledDefinition"
                  />
                </v-col>
              </v-container>
            </v-row>
            <v-data-table
              :headers="dgdConfig.headers"
              :items="dgdConfig.items"
              v-model="definitionGroupDetails"
              locale="es-ES"
              fixed-header
              show-select
              dense
              class="elevation-0"
              item-key="DedID"
              :loading="dgdConfig.loading"
              @item-selected="definitionSelected"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _sDefinitionGroup from "@/services/General/DefinitionGroupService";
import _sDefinition from "@/services/General/DefinitionService";
export default {
  data: () => ({
    defID: 0,
    dgrID: 0,
    definitionList: [],
    definitionGroup: {},
    definitionGroupDetails: [],
    disabledDetail: true,
    dgrConfig: {
      model: {
        DgrID: "ID",
        DefID: "int",
        DgrDescription: "string",
        SecStatus: "status",
      },
      title: "Grupo de Definiciones",
      service: _sDefinitionGroup,

      headers: [
        {
          text: "ID",
          align: "end",
          value: "DgrID",
          width: "10%",
        },
        {
          text: "Grupo",
          sortable: true,
          value: "DgrDescription",
        },
        {
          text: "Estado",
          value: "SecStatus",
          sortable: true,
          align: "center",
          width: "20%",
        },
      ],
    },
    dgdConfig: {
      loading: false,
      headers: [
        {
          text: "DgdID",
          value: "DgdID",
          sortable: false,
          align: "end",
          width: "10%",
        },
        {
          text: "DedID",
          value: "DedID",
          sortable: false,
          align: "end",
          width: "10%",
        },
        {
          text: "Detalle",
          value: "DedDescription",
          sortable: false,
        },
        {
          text: "Valor",
          value: "DedValue",
          sortable: false,
          align: "center",
          width: "5%",
        },
      ],
      items: [],
    },
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      _sDefinition.list(this.$fun.getUserID()).then((r) => {
        this.definitionList = r.data;
      });
    },

    rowSelected(items) {
      if (items.length > 0) {
        this.dgrID = items[0].DgrID;
        this.defID = items[0].DefID;
        this.definitionGroup = items[0];
        this.disabledDetail = items[0].SecStatus == 0;
      } else {
        this.dgrID = 0;
        this.defID = 0;
        this.definitionGroup = {};
        this.disabledDetail = true;
      }
    },
    saveDgr(item) {
      if (item.DgrDescription.length == 0) {
        this.$fun.alert("Registre descripción de grupo", "warning");
        return;
      }

      item.save();
    },
    loadDefinition() {
      if (this.definition.details == undefined)
        _sDefinition.definitiongroup(this.defID, 0, this.$fun.getUserID()).then((r) => {
          this.definition.details = r.data;
          this.dgdConfig.items = r.data;
        });
      else this.dgdConfig.items = this.definition.details;
    },
    loadDefinitionGroup() {
      this.dgdConfig.loading = true;
      _sDefinition.definitiongroup(this.defID, this.dgrID, this.$fun.getUserID()).then((r) => {
        this.definitionGroupDetails = r.data;
        this.dgdConfig.loading = false;
       /*  console.log(this.definitionGroupDetails); */
      });
    },

    definitionSelected(e) {
      var dgd = { DgrID: this.dgrID, DedID: e.item.DedID };

      if (e.value) {
        dgd.SecStatus = 1;
        _sDefinitionGroup.insertDetail(dgd, this.$fun.getUserID());
      } else {
        dgd.SecStatus = 0;
        _sDefinitionGroup.updateDetail(dgd, this.$fun.getUserID());
      }
    },
  },
  watch: {
    defID() {
      this.definitionGroupDetails = [];
      if (this.defID > 0) {
        this.definitionGroup.DefID = this.defID;
        this.definition = this.definitionList.find((x) => x.DefID == this.defID);
        this.loadDefinitionGroup();
        this.loadDefinition();
      } else {
        this.dgdConfig.items = [];
        this.definition = {};
      }
    },
  },
  computed: {
    disabledDefinition() {
      return this.definitionGroupDetails.filter((x) => x.SecStatus == 1).length > 0;
    },
  },
};
</script>
